.popup {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  background-color: rgba(42, 68, 60, 0.8);
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  position: relative;
  padding: 16px 16px 70px 16px;
  width: 100%;
  max-width: 700px;

  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.popup-inner .close-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;

  font-size: 16px;
}

.popup-inner header {
  margin-bottom: 30px;
  font-weight: bold;
  color: #2a443c; /* Darker shade for better contrast */
  border: 2px solid var(--theme-secondary-light); /* Solid border with theme color */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Space inside the border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Styles for screens smaller than 768px */
@media (max-width: 768px) {
  .popup-inner {
    padding: 16px 6px 16px 6px;

    max-width: 95%;
  }

  .popup-inner .close-btn {
    font-size: 14px;
    bottom: 5px;
    right: 5px;
  }

  .popup-inner div {
    margin-bottom: 40px;
  }
}
