.services-container {
  margin: auto;
  max-width: 80%;
}

.callout {
  font-size: larger;
}

.addon-options {
  display: list-item;
  list-style: circle;
}

@media (max-width: 1367px) {
  .callout {
    font-size: large;
  }
}

@media (max-width: 768px) {
  .services-container {
    max-width: 100%;
  }

  .callout {
    font-size: medium;
  }
}
