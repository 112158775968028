.footer-content {
  display: flex;
  justify-content: space-around; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

.footer-content i {
  margin: 5px;
}

.biz-hours {
  display: flex;
  flex-direction: column;
  align-items: start;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .biz-hours {
    align-items: center;
    margin-bottom: 1rem;
  }

  .social-links {
    margin-bottom: 1rem;
  }
}
