.team-member-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  gap: 10px; /* Spacing between elements */

  align-items: center;
  padding: 1rem;

  max-width: 80%;
  margin: auto;
}

.team-member-item {
  transition: transform 0.2s; /* Smooth transition for hover effect */
  background-color: #f8f8f8;
  width: 75%;
  line-height: 27px;
}

.team-member-item img {
  border-radius: 50%;
  width: 150px; /* Fixed width for images */
  height: 150px; /* Fixed height for images */
  margin-right: 2rem;
}

.team-member-item h3 {
  line-height: 50px;
}

.team-member-item:hover {
  transform: scale(1.02); /* Slightly enlarge the card on hover */
}

.item-header {
  display: flex;
}

.name-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-body {
  padding: 5px 20px 5px 20px;
}

/* Styles for screens smaller than 768px */
@media (max-width: 768px) {
  .team-member-list {
    max-width: 100%;
    padding: 5px;
  }

  .team-member-item {
    width: 100%;
  }

  .team-member-item h3 {
    line-height: 30px;
  }
}
