.job-interest-form {
  width: 35%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .job-interest-form {
    width: 80%;
  }
}
