:root {
  --main-text-color: #484848;
  --theme-secondary-light: #548a7b;
  --theme-primary-background: #dce3e3;
  --button-text-color: #e8ebd4;
}

/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General styles */
html {
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

body {
  background-color: var(--theme-primary-background);
  color: var(--main-text-color);

  height: 100vh;
  margin: auto;

  font-family: "Poppins", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans",
    Arial, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
}

section {
  margin: auto;
  max-width: 100%;
  padding: 20px;
}

button {
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 22px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  color: var(--button-text-color);
  height: 50px;
  margin: 5px;
  background-color: #ae4e22;
}

button:hover {
  background-color: #c75d2c;
}

footer {
  text-align: center;
  padding: 10px;
}

/* Glow effect for links on hover */
a {
  transition: text-shadow 0.3s ease;
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}

a:hover {
  text-shadow: 0 0 8px var(--theme-secondary-light);
}

address {
  font-weight: 500;
}

summary {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 26px;
}

summary::-webkit-details-marker {
  display: none;
}

summary::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid var(--main-text-color);
  border-inline: 7px solid transparent;
  transition: 0.2s;
}

details[open] > summary {
  border-bottom: 2px solid #fff9f1;
}

details[open] > summary::after {
  transform: rotate(-180deg);
}

summary::-webkit-details-marker {
  display: none;
}

details[open] summary {
  border-radius: 5px 5px 0 0;
}

details {
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  margin: 16px;
}

article {
  padding: 1rem;
  font-size: 26px;
}

details ul {
  margin: 1rem 0 0.5rem 1rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 3rem;
  font-size: x-large;
}

/* Styles for the sections */
#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
}

/* This pseudo element is creating an opacity on the main background image */
#main::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/massage.webp");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6; /* Opacity applied to the pseudo-element */
  z-index: -1; /* Place the pseudo-element behind the content */
}

#main {
  position: relative;
  height: 800px;

  display: flex;
  flex-direction: column;
  justify-content: end;

  color: var(--theme-secondary-light);
}

#testimonials {
  background-color: #f6f6f6;
}

/* Specific styles */
.testimonials-header {
  width: 30%;
}

.testimonials-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.main-buttons {
  display: flex;
  justify-content: end;
  gap: 500px;
}

.main-content {
  flex-grow: 1;
  padding: 5px 20px;
}

.center-text {
  text-align: center;
}

.big-text-1 {
  font-size: 5rem;
}

.big-text-2 {
  font-size: 4rem;
}

.big-text-3 {
  font-size: 2rem;
}

.glow {
  text-shadow: 0 0 6px var(--theme-secondary-light);
}

.fancy-font {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
}

.fancy-bold {
  text-shadow: 2px 2px 0px var(--theme-secondary-light);
}

.bold {
  font-weight: bold;
}

.card {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.container {
  margin: auto;
  max-width: 90%;
  padding: 20px;
}

.left-justify {
  text-align: left;
}

.indent-1 {
  text-indent: 3rem;
}

.indent-2 {
  text-indent: 6rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form input {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid var(--theme-secondary-light);
}

.reviews {
  width: 80%;
  margin: 0 auto;
  height: 400px;
  overflow: hidden;
}

.policies {
  margin-top: 70px;
  padding: 40px;
}

.menu-btn {
  background-color: var(--theme-secondary-light);
  display: none;
}

.round-img {
  border-radius: 50%;
  width: 150px; /* Fixed width for images */
  height: 150px; /* Fixed height for images */
  margin-right: 2rem;
}

.float-left {
  float: left;
  margin-right: 10px; /* Adds some spacing between the image and the text */
}

@media (max-width: 1367px) {
  button {
    height: 40px;
  }

  article {
    font-size: 22px;
  }

  summary {
    font-size: 18px;
  }

  .big-text-1 {
    font-size: 4rem;
  }

  .policies {
    margin-top: 40px;
  }

  .main-content {
    padding: 0px;
  }
}

/* Styles for screens smaller than 768px */
@media (max-width: 768px) {
  body {
    font-size: smaller;
  }

  summary {
    font-size: 14px;
  }

  details {
    margin: 5px;
  }

  details ul {
    margin: 0;
    flex-direction: column;
    font-size: small;
    gap: 0.5rem;
  }

  summary {
    padding: 5px 10px;
  }

  article {
    font-size: 12px;
    padding: 0;
  }

  #main {
    width: 100%;
    height: 400px;
    justify-content: center;
    margin: auto;
    padding: 0px;
  }

  #testimonials {
    width: 100%;
    margin: auto;
    padding: 10px 5px;
  }

  .main-buttons {
    flex-direction: column;
    gap: 0px;
  }

  .card {
    padding: 10px;
  }

  .main-content {
    padding: 3px;
  }

  .big-text-1 {
    font-size: 2.75rem;
  }

  .big-text-2 {
    font-size: 2.5rem;
  }

  .big-text-3 {
    font-size: 2.25rem;
  }

  .indent-1 {
    text-indent: 1rem;
  }

  .indent-2 {
    text-indent: 2rem;
  }

  .fancy-bold {
    text-shadow: 1px 1px 0px var(--theme-secondary-light);
  }

  .testimonials-header {
    width: 100%;
  }

  .testimonials-footer {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
  }

  .reviews {
    width: 100%;
    height: 380px;
  }

  .menu-btn {
    display: block;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
}
