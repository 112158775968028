/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  font-size: 18px;

  max-height: 80px;
}

.logo {
  margin: 5px 0 0 20px;
  max-width: 220px;
}

.nav-left {
  display: flex;
  margin-right: 25px;
}

.nav-right {
  display: flex;
  margin-left: 25px;
}

.nav-elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-elements a {
  padding: 10px; /* Add padding to the links */
  margin: 0 10px 0 10px;
}

@media (max-width: 1367px) {
  .navbar {
    font-size: 16px;
  }

  .logo {
    max-width: 150px;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    margin: 0 0 0 0;
    text-align: center;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 0px 5px;
    font-size: 22px;

    max-height: fit-content;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    max-width: 95%;
    margin: auto;
  }

  .nav-elements {
    visibility: hidden;
    display: none;
    animation: slideInOut 1s ease-in-out;
  }

  .open {
    opacity: 1;
    visibility: visible;
    display: block;
  }
}

@keyframes slideInOut {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-200px);
  }

  50% {
    opacity: 0.5;
    visibility: visible;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
